

import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
import { RubriqueEnum } from "@/utils/Enums";
import OperationCoefficientException from "@/models/OperationCoefficientException"
import OperationCoefficientConfigException from "@/models/OperationCoefficientConfigException"
const rootPath = 'operations';
import  i18n  from '@/i18n';

class OperationExceptionService {
  
    public getOperationCoefficientExceptionsTableUrl(bopModelId:string,lang:string):string{
        return `${apiProvider.getUrl()}${rootPath}/coefficientsExceptions${toUrl({lang,bopModelId})}`;
 
    }
    
    public getOperationCoefficientConfigExceptionsTableUrl(bopModelId:string,lang:string):string{
        return `${apiProvider.getUrl()}${rootPath}/coefficientOptionExceptions${toUrl({lang,bopModelId})}`;
 
    }

    /**
     * For calo tuy only
     * @param coefficientOptionId
     * @param exceptionValue
     */
    public getOperationExceptionCodes(coefficientOptionId:string,exceptionValue:number){
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/ExceptionOperationByCoefficientOptions${toUrl({coefficientOptionId,exceptionValue})}`);
    }

    
    
    public SaveOperationCoefficientConfigExceptions(operationCoefficientExceptions:Array<OperationCoefficientConfigException>):AxiosPromise{
        return apiProvider.post(`${apiProvider.getUrl()}${rootPath}/coefficientOptionExceptions`,operationCoefficientExceptions);
 
    }
    
    
    public SaveOperationCoefficientsExceptions(operationCoefficientExceptions:Array<OperationCoefficientException>):AxiosPromise{
        return apiProvider.post(`${apiProvider.getUrl()}${rootPath}/coefficientsExceptions`,operationCoefficientExceptions);
 
    }
    public deleteCoefficientOperationException(operationId:string,coefficientId:string):AxiosPromise{
        return apiProvider.delete(`${apiProvider.getUrl()}${rootPath}/coefficientsExceptions${toUrl({coefficientId,operationId})}`)
      }
    public deleteOperationCoefficientOptionException(exceptionId:string):AxiosPromise{
        return apiProvider.delete(`${apiProvider.getUrl()}${rootPath}/coefficientOptionExceptions/${exceptionId}`);
      }
   
}

export default new OperationExceptionService();