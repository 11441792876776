import { Guid } from "guid-typescript";

export default class OperationCoefficientConfigException{

      public id:string;
      public operationId:string;
      public operationName:string;
      public  OperationCode:string;

      public coefficientOptionDto1:Object;
      public  coefficientOptionDto2:Object;

      constructor(){
          this.id = Guid.create().toString()
          this.operationId = "";
          this.operationName = "";
          this.OperationCode = "";
          this.coefficientOptionDto1 = {
              id : Guid.createEmpty().toString()
          };
          this.coefficientOptionDto2 = {
              id: Guid.createEmpty().toString()
          };
         
      }
}