import { Guid } from 'guid-typescript';
import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
import CoefficientConfig from "@/models/CoefficientConfig"
import FournitureReference from '@/models/FournitureReference';
const rootPath = 'coefficientoptions';


class CoefficientOptionService {
  
    public  GetCoefficientOptionsTableUrl(coefficientId:string,lang:string,isAdmin = true): string {
        return `${apiProvider.getUrl()}${rootPath}${toUrl({lang ,coefficientId,isAdmin})}`;
    }
    
    public GetCoefficientOptionsByCoefficient(coefficientId:string,lang:string,isAdmin:boolean = false){
        return apiProvider.get(this.GetCoefficientOptionsTableUrl(coefficientId,lang,isAdmin));
    }
 
    
    public GetCoefficientOptionsByKey(coefficientKey:string,bopModelId:string,lang:string){
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/byKey${toUrl({lang ,coefficientKey,bopModelId})}`);
    }
    public GetPossibleCoefficientOptions(coefficientId:string,operationId:string,lang:string){
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/getPossibleCoefficientOptions${toUrl({lang ,operationId,coefficientId})}`);
    }
 
    public async SaveCoefficientOptions(coefficientConfig:Array<Object>,lang:string): Promise<any> {
        return apiProvider.post(`${apiProvider.getUrl()}${rootPath}${toUrl({lang})}`,coefficientConfig);
    }

    public  GetVisibleOperationFamiliesUrl(coefficientId:string,lang:string): string {
        return `${apiProvider.getUrl()}${rootPath}/GetVisibleOperationFamilies${toUrl({coefficientId,lang})}`;
    }
    public async SetVisibleOperationFamilies(fournitureReferenceDtos:Array<FournitureReference>): Promise<any> {
        return apiProvider.post(`${apiProvider.getUrl()}${rootPath}/SetVisibleOperationFamilies`,fournitureReferenceDtos);
    }

    public deleteCoefficientOption(coefficientOptionId:string):AxiosPromise{
        return apiProvider.delete(`${apiProvider.getUrl()}${rootPath}/${coefficientOptionId}`)
    }

    public deleteNatureDesTachesCoefficient(coefficientOptionId:string,operationFamiliesIds:string[]):AxiosPromise{
        return apiProvider.post(`${apiProvider.getUrl()}${rootPath}/delete-nature-des-taches/${coefficientOptionId}`,operationFamiliesIds)
    }
}

export default new CoefficientOptionService();