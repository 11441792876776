import {OperationType} from "@/utils/Enums";
import apiProvider from "@/utils/api";
import {AxiosPromise} from "axios";
import {toUrl} from "@/utils/helpers";
import {RubriqueEnum} from "@/utils/Enums";

const rootPath = "operations";

class OperationService {
    public getOperationsPrincipalesTableUrl(
        bopModelId: string,
        lang: string,
        operationFamilyRootKey: string = "",
        addApiHeader: boolean = false
    ): string {
        let operationType = RubriqueEnum.OperationPrincipal;

        return `${apiProvider.getUrl()}${rootPath}/BopModelSetupListingOperations${toUrl(
            {lang, bopModelId, operationType, operationFamilyRootKey, addApiHeader}
        )}`;
    }

    public getOperationsDiversesTableUrl(
        bopModelId: string,
        typeTravauxFilter: Array<string>,
        lang: string
    ): string {
        let operationType = RubriqueEnum.OperationDiverse;
        let typeTravauxFilterUrl = "";
        typeTravauxFilter.forEach((el) => {
            typeTravauxFilterUrl += `&typeTravaux=${el}`;
        });
        return `${apiProvider.getUrl()}${rootPath}/BopModelSetupListingOperations${toUrl(
            {lang, bopModelId, operationType}
        )}${typeTravauxFilterUrl}`;
    }

    public saveOperations(operations: Array<any>, lang: string): AxiosPromise {
        return apiProvider.post(`${rootPath}${toUrl({lang: lang})}`, operations);
    }

    public getOperationsExceptions(
        bopModelId: string,
        lang: string
    ): AxiosPromise {
        return apiProvider.get(
            `${apiProvider.getUrl()}${rootPath}/operationExceptions${toUrl({
                lang,
                bopModelId,
            })}`
        );
    }

    public getBopOperations(
        bopModelId: string,
        operationType: number,
        lang: string,
        typeTravaux: string | null = null,
        siteId: any = null,
        operationFamilyId: any = undefined
    ): AxiosPromise {
        let typeTravauxFilterUrl = "";

        let query: any = {lang, operationType, bopModelId};
        if (operationFamilyId) query["operationFamilyId"] = operationFamilyId;
        if (siteId) query["siteId"] = siteId;
        if (typeTravaux) query["typeTravaux"] = typeTravaux;
        return apiProvider.get(
            `${apiProvider.getUrl()}${rootPath}/bopSetupOperations${toUrl(query)}`
        );
    }
    public getTuyauteriePlastiqueBopOperations(
        lang: string,
        rootOperationFamilyId:string
    ): AxiosPromise {
        const query: any = {lang, rootOperationFamilyId};
        return apiProvider.get(
            `${apiProvider.getUrl()}${rootPath}/tuyauteriePlastique/bopSetupOperations${toUrl(query)}`
        );
    }

    public getBopOperationsWithExcpetion(
        bopModelId: string,
        lang: string,
        operationFamilyId: any = undefined,
        coefficientOptionId: string | null = null,
        exceptionValue: number | null = null
    ): AxiosPromise {
        const operationType = OperationType.OperationPrincipal;
        let query: any = {lang, bopModelId, operationType};
        if (operationFamilyId) query["operationFamilyId"] = operationFamilyId;
        if (coefficientOptionId) query["coefficientOptionId"] = coefficientOptionId;
        if (exceptionValue) query["exceptionValue"] = exceptionValue;
        return apiProvider.get(
            `${apiProvider.getUrl()}${rootPath}/bopSetupOperations${toUrl(query)}`
        );
    }

    public getBopOperationsSupp(lang: string): AxiosPromise {
        return apiProvider.get(
            `${apiProvider.getUrl()}${rootPath}/bopSetupOperationsSupp${toUrl({
                lang,
            })}`
        );
    }

    public getUsedSiteTypeTravauxByBopModelId(bopModelId: string): AxiosPromise {
        return apiProvider.get(
            `${apiProvider.getUrl()}${rootPath}/usedSiteTypeTravaux${toUrl({
                bopModelId,
            })}`
        );
    }

    public getOperationUnitPoint(
        operationId: string,
        coefficientOptionId: string
    ): AxiosPromise {
        return apiProvider.get(
            `${apiProvider.getUrl()}${rootPath}/operationUnitPoint${toUrl({
                operationId,
                coefficientOptionId,
            })}`
        );
    }

    public getBopCalorifugeageAppareilOperations(
        revetementTole: string | null,
        natureAppareil: string | null,
        typeIsolation: string | null,
        epaisseurIsolation: string | null,
        epaisseur: string | null,
        operationFamilyKey: string,
        lang: string
    ): AxiosPromise {
        let query: any = {operationFamilyKey, lang};
        if (revetementTole) {
            query["revetementTole"] = revetementTole;
        }
        if (revetementTole) {
            query["natureAppareil"] = natureAppareil;
        }
        if (typeIsolation) {
            query["typeIsolation"] = typeIsolation;
        }
        if (epaisseurIsolation) {
            query["epaisseurIsolation"] = epaisseurIsolation;
        }
        if (epaisseur) {
            query["epaisseur"] = epaisseur;
        }

        return apiProvider.get(
            `${apiProvider.getUrl()}${rootPath}/calorifugeageAppareil/bopSetupOperations${toUrl(
                query
            )}`
        );
    }

    public deleteOperation(operationId: string, operationUnitValueIds: string[], isOperationDiverse: boolean): AxiosPromise {
        return apiProvider.post(`${apiProvider.getUrl()}${rootPath}/${operationId}${toUrl({
            isOperationDiverse
        })}`, {operationId,operationUnitValueIds});
    }
    public bulkDeleteOperation(operations:Array<bulkDeleteOperationRequest>, isOperationDiverse: boolean): AxiosPromise {
        const params = {
            isOperationDiverse
        }
        return apiProvider.post(`${apiProvider.getUrl()}${rootPath}/bulkDelete${toUrl(params)}`,operations)
    }
}
export type bulkDeleteOperationRequest = {
    operationId: string,
    operationUnitValueIds: string[]
}
export default new OperationService();
