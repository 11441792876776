
























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import CoefficientConfig from "@/models/CoefficientConfig";
import CoefficientService from "@/services/CoefficientService";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import UnitService from "@/services/UnitService";
import { Guid } from "guid-typescript";
import OperationCoefficientConfigException from "@/models/OperationCoefficientConfigException";
import OperationService from "@/services/OperationService";
import OperationExceptionService from "@/services/OperationExceptionService";
import { BopModelEnum, UserRoleEnum } from "@/utils/Enums";
import BopModelService from "@/services/BopModelService";
import Constants from "@/utils/Constants";
type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
  },
})
export default class OperationCoefficientConfigurationExceptionSetup extends Vue {
  @Prop({ required: true }) public bopModelId!: string;

  public data: Data = {
    columns: [],
    rows: [],
  };
  private coefficients: Array<any> = [];
  public showModal: boolean = false;
  private url: string = OperationExceptionService.getOperationCoefficientConfigExceptionsTableUrl(
    this.bopModelId,
    this.$i18n.locale
  );
  private modifiedCoefficientConfigs: Array<
    OperationCoefficientConfigException
  > = [];

  get isNotAllowedToEdit(): Boolean {
    return !this.$store.getters.isAllowed(UserRoleEnum.centralAdministrator);
  }
  /**
   * Methods
   */
  private async remove(data: any) {
    const modalResponse = await this.$bvModal.msgBoxConfirm(
      this.$t("bopModelSetup.coefficientOptionException.confirmDeleteMessage").toString(),
      {
        title: this.$t(
          "bopModelSetup.coefficientOptionException.confirmDeleteTitle"
        ).toString(),
        size: "lg",
        buttonSize: "lg",
        okVariant: "primary",
        cancelVariant: "secondary",
        okTitle: this.$t(
          "bopModelSetup.coefficientOptionException.confirmDeleteYes"
        ).toString(),
        cancelTitle: this.$t(
          "bopModelSetup.coefficientOptionException.confirmDeleteNo"
        ).toString(),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
        dialogClass: "modal-app",
      }
    );

    if (modalResponse) {
      // console.log(data);
      const rowData = data.rowData;
      try {
        const response = await OperationExceptionService.deleteOperationCoefficientOptionException(
          rowData.id);
        if (response.status === 200) {
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t(
              "bopModelSetup.coefficientOptionException.deleteSuccessTitle"
            ).toString(),
            text: this.$t(
              "bopModelSetup.coefficientOptionException.deleteSuccess"
            ).toString(),
          });
          const indexToRemove = this.modifiedCoefficientConfigs.findIndex(el => el.id === rowData.id);
          this.modifiedCoefficientConfigs.splice(indexToRemove,1);
          (<any>this.$refs.table).deleteRow(data.rowIndex);
          this.$nextTick().then(() => {
            this.$store.commit('changeHasBeenModified',this.modifiedCoefficientConfigs.length !== 0);
          })
        }
      } catch (err) {
        const errResponse = err.response;
        let text = this.$t(
          "bopModelSetup.coefficientOptionException.deleteError"
        ).toString();
        if (errResponse.status === 500) {
          if (errResponse.data.detail === "instanciatedInBop") {
            text = this.$t(
              "bopModelSetup.coefficientOptionException.deleteErrorUsedInBop"
            ).toString();
          } else if (errResponse.data.detail === "definingAnOperation") {
            text = this.$t(
              "bopModelSetup.coefficientOptionException.definingAnOperation"
            ).toString();
          }
        }
        this.$notify({
          group: "globalError",
          type: "error",
          title: this.$t(
            "bopModelSetup.coefficientOptionException.deleteErrorTitle"
          ).toString(),
          text,
          duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
        });
      }
    }
  }
  public async save() {
    if (this.modifiedCoefficientConfigs.length) {
      try {
        const response = await OperationExceptionService.SaveOperationCoefficientConfigExceptions(
          this.modifiedCoefficientConfigs
        );

        if (response.status === 200) {
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t(
              "bopModelSetup.CoefficientConfigException.saveSuccess.title"
            ).toString(),
            text: this.$t(
              "bopModelSetup.CoefficientConfigException.saveSuccess.title"
            ).toString(),
          });
          this.refresh();
        }
      } catch (err) {
        const responseError = err.response;
        if (responseError.status === 400) {
          if (responseError.data === "duplicateConfiguration") {
            this.showNotification(
              "error",
              this.$t(
                "bopModelSetup.CoefficientConfigException.errorSave.title"
              ).toString(),
              this.$t(
                "bopModelSetup.CoefficientConfigException.errorSave.duplicateConfiguration"
              ).toString()
            );
            return;
          }
          this.showNotification(
            "error",
            this.$t(
              "bopModelSetup.CoefficientConfigException.errorSave.title"
            ).toString(),
            this.$t(
              "bopModelSetup.CoefficientConfigException.errorSave.incorrectValues"
            ).toString()
          );
        } else {
          this.showNotification(
            "error",
            this.$t(
              "bopModelSetup.CoefficientConfigException.errorSave.title"
            ).toString(),
            this.$t(
              "bopModelSetup.CoefficientConfigException.errorSave.uknownError"
            ).toString()
          );
        }
      }
    }
  }
  private showNotification(type: string, title: string, text: string) {
   const isError = (type === "error");
      let notifObject: any = {
      group: isError ? "globalError": "global",
      type,
      title,
      text,
    };
    if (type === "error") {
      notifObject.duration = Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS;
    }
    this.$notify(notifObject);
  }
  public async addData() {
    const newOperationCoefficientConfigException = new OperationCoefficientConfigException();
    (<any>this.$refs.table).updatedRows.unshift(
      newOperationCoefficientConfigException
    );
    (<any>this.$refs.table).modify(newOperationCoefficientConfigException);
  }

  public refresh(): void {
    this.modifiedCoefficientConfigs = [];
    (<any>this.$refs.table).refreshAPI();
  }

  /**
   * Hooks
   */
  public async beforeMount() {
    const bopModel = (
      await BopModelService.getBopModel(this.bopModelId, this.$i18n.locale)
    ).data;
    // gets the coefficient metadata to construct the table
    this.coefficients = (
      await CoefficientService.getExceptionCoefficientConfigTypes(
        this.bopModelId,
        this.$i18n.locale
      )
    ).data;
    const operations = (
      await OperationService.getOperationsExceptions(
        this.bopModelId,
        this.$i18n.locale
      )
    ).data;
    let columns: any = [
      {
        name: "dropdown",
        title: this.$t("bopModelSetup.exception.operation"),
        sortField: "operationName",
        property: "operationId",
        display: "operationName",
        options: operations,
        valueField: "key",
        textField: "value",
      },
    ];
    for (let i = 0; i < this.coefficients.length; i++) {
      if (i >= 2) break;

      let coefficient = this.coefficients[i];
      const coefficientConfigs: any = (
        await CoefficientOptionService.GetCoefficientOptionsByCoefficient(
          coefficient.id,
          this.$i18n.locale
        )
      ).data;
      columns.push({
        name: "dropdown",
        title: coefficient.name,
        sortField: `coefficientOptionDto${i + 1}.value`,
        property: `coefficientOptionDto${i + 1}.id`,
        display: `coefficientOptionDto${i + 1}.value`,
        options: coefficientConfigs,
        valueField: "id",
        textField: "value",
      });
    }
    if (bopModel.key == BopModelEnum.CalorifugageTuyauterie) {
      columns.push({
        name: "editable",
        sortField: "exceptionValue",
        title: this.$t("coefficients.epaisseur"),
        property: "exceptionValue",
      });
    }
    columns.push({
      name: "actions",
      title: "",
      removeButton: true,
      duplicateButton: false,
      disableRemoveButton: this.isNotAllowedToEdit,
    });
    this.data.columns = columns;
  }
}
